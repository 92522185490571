import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IonlineUserDetail } from '../interface/interface.service';

@Injectable({
  providedIn: 'root'
})
export class OnlineUserService {
  messageSource = new BehaviorSubject<IonlineUserDetail[]>([]);
  currentMessage = this.messageSource.asObservable();
  constructor() { }

  changeMessage(data: IonlineUserDetail[]){
    this.messageSource.next(data);
  }
}
